(function ($) {
  Drupal.behaviors.productReplenishmentSelect = {
    attach: function (context, settings) {
      // Add replenishment menu event if enable_replenishment=true in config.
      if (!settings.common || !settings.common.has_replenishment) {
        return null;
      }

      var $tmpl = $('.product-replenishment-select', context);
      var $form = $('.js-product-replenishment-select__form', $tmpl);
      var $opts = $("input[type='radio']", $form);
      var $addBtn = $('.js-add-to-cart', '.js-product');
      var $replenishmentSelect = $('.js-replenishment-select', context);

      // Make the auto-replenishment i icon work only on click using tooltipster library
      $('.product-replenishment__icon').tooltipster({
        theme: 'tooltipster-shadow',
        content: $(this).attr('title'),
        position: 'bottom',
        trigger: 'click',
        maxWidth: 184
      });

      $opts.once().click(function () {
        var val = $(this).val();
        var $dd = $('.js-product-replenishment-select__options', $tmpl);

        if (!$dd.length) {
          return;
        }

        // Slide down helper
        var sd = function ($ele) {
          if (!$ele.hasClass('active')) {
            $ele.slideDown('fast', function () {
              $(this).addClass('active');
            });
          }
        };
        // Slide up helper
        var su = function ($ele) {
          if ($ele.hasClass('active')) {
            $ele.slideUp('fast', function () {
              $ele.removeClass('active');
            });
          }
        };

        if (val == 'replenish') {
          sd($dd);

          // Set the replenishment back to whatever is selected
          $addBtn.attr('data-replenishment', $replenishmentSelect.val());
        } else {
          su($dd);

          // Set the replenishment back to off
          $addBtn.attr('data-replenishment', 0);
        }
      });

      // When the replenishment select changes, update the add-to-bag
      // button's data-replenishment value.
      $replenishmentSelect.on('change', function () {
        $addBtn.attr('data-replenishment', this.value);
      });

      // Set add-to-bag default replenishment value to 0.
      $addBtn.attr('data-replenishment', 0);

      // Replenishment information (?)
      $('.js-product-replenishment-select__option--replenish sup', $form)
        .once()
        .click(function (e) {
          var template = 'product_replenishment_message';

          if (_.isEmpty(site.templates[template])) {
            console.log('Missing javascript template: ' + template);

            return;
          }

          var html = site.template.get({
            name: template,
            data: {}
          });

          generic.overlay.launch({
            content: html,
            height: '300px',
            width: '500px'
          });
        });

      var self = this;

      $(document).on('quickshop.loaded', function (event, newContext) {
        self.attach(newContext, Drupal.settings);
      });
    }
  };
})(jQuery);
